import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import EventsCalendar from "../../components/EventsCalendar/EventsCalendar";
import { useNavigate } from "react-router-dom";
import { useGetAdminEventsQuery } from "../../store/slices/apiSlice"; // Импортируем наш запрос из apiSlice
import styles from "./CalendarCard.module.css";

export default function CalendarCard() {
  const navigate = useNavigate();

  // Используем наш кастомный хук для получения данных о событиях для админов
  const { data: eventsData, isLoading, isError } = useGetAdminEventsQuery({
    refetchOnMountOrArgChange: true,
  });

  const [events, setEvents] = useState([]);
  const [formatedDates, setFormatedDates] = useState([]);

  useEffect(() => {
    if (eventsData && eventsData.length > 0) {
      const formattedEvents = eventsData.map((event) => ({
        id: event.id,
        name: event.title,
        descr: event.content,
        date: new Date(event.dateTime).toLocaleDateString("ru-RU").slice(0, 5),
        time: new Date(event.dateTime).toLocaleTimeString("ru").slice(0, 5),
        finishTime: !event.finishTime ? "" : new Date(event.finishTime).toLocaleTimeString("ru").slice(0, 5),
        place: event.place,
        tag: event.tags, // Используем массив tags напрямую
        moderator: event.moderators?.map((mod) => ({
          name: mod,
        })),
        speakers: event.speakers?.map((speaker) => ({
          name: speaker,
        })),
        minorSpeakers: event.minorSpeakers,
        status: event.status,
      }));
  
      setEvents(formattedEvents);
  
      const dates = formattedEvents.map((event) => event.date);
      const uniqueDates = [...new Set(dates)].sort(
        (a, b) => new Date(a) - new Date(b)
      );
      setFormatedDates(uniqueDates);
    } else {
      setEvents([]);
      setFormatedDates([]);
    }
  }, [eventsData]);
  

  const groupByDate = Object.groupBy(events, (item) => item.date);

  if (isLoading) return <p>Загрузка...</p>;
  if (isError) return <p>Ошибка при загрузке данных</p>;

  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <h2>Календарь событий</h2>
        <Button
          label="Создать событие"
          icon="pi pi-plus"
          className={styles.custom_btn}
          onClick={() => navigate("./create")}
        />
      </div>
      <EventsCalendar
        dates={formatedDates}
        groupByDate={groupByDate}
        user={false}
        admin={true}
      />
    </div>
  );
}
