import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import styles from "./UserOrderTemplate.module.css";

export default function UserOrderTemplate({ user, editDisabled, onChange = () => {} }) {
  const [form, setForm] = useState({
    tariff: user.tariff,
    company: user.company,
    position: user.position,
    firstName: user.firstName,
    middleName: user.middleName,
    lastName: user.lastName,
    phone: user.phone,
    email: user.email,
  });

  const [selectedTariff, setSelectedTariff] = useState(
    user.tariff ? { name: user.tariff } : null
  );

  const tariff = [{ name: "VIP" }, { name: "Бизнес" }];

  useEffect(() => {
    setForm(user); // Обновляем форму при изменении пропсов
    setSelectedTariff(user.tariff ? { name: user.tariff } : null);
  }, [user]);

  useEffect(() => {
    onChange(form); // Передаем начальные данные в родительский компонент при монтировании
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = { ...form, [name]: value };
    setForm(updatedForm);
    onChange(updatedForm); // Вызываем функцию обратного вызова, чтобы передать данные в родительский компонент
  };

  return (
    <div className={styles.order}>
      <div className={styles.item}>
        <div className={styles.column}>
          <label id="tariff">Тариф</label>
          {editDisabled ? (
            <InputText
              aria-describedby="tariff"
              name="tariff"
              value={selectedTariff?.name || ""}
              disabled
            />
          ) : (
            <Dropdown
              aria-labelledby="tariff"
              name="tariff"
              value={selectedTariff}
              onChange={(e) => {
                setSelectedTariff(e.value);
                handleChange({ target: { name: "tariff", value: e.value.name } });
              }}
              options={tariff}
              optionLabel="name"
              placeholder="Выберите тариф"
              className={styles.dropdown_text}
            />
          )}
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="company">Компания</label>
          <InputText
            aria-describedby="company"
            name="company"
            value={form.company}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="position">Должность</label>
          <InputText
            aria-describedby="position"
            name="position"
            value={form.position}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>
      </div>

      <div className={styles.item}>
        <div className="flex flex-column gap-2">
          <label htmlFor="lastName">Фамилия</label>
          <InputText
            aria-describedby="lastName"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="firstName">Имя</label>
          <InputText
            aria-describedby="firstName"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="middleName">Отчество</label>
          <InputText
            aria-describedby="middleName"
            name="middleName"
            value={form.middleName}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>
      </div>

      <div className={styles.item}>
        <div className="flex flex-column gap-2">
          <label htmlFor="phone">Телефон</label>
          <InputMask
            aria-describedby="phone"
            id="phone"
            name="phone"
            mask="+7 (999) 999-99-99"
            placeholder="+7 (999) 111-11-11"
            value={form.phone}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="email">Email</label>
          <InputText
            aria-describedby="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>
      </div>
    </div>
  );
}
