import { v4 as uuidv4 } from "uuid";

const ExportToIcs = (event) => {
    const [day, month] = event.date.split(".");
    const [hour, minute] = event.time.split(":");
    const year = new Date().getFullYear();

    // Формируем время в формате YYYYMMDDTHHMMSS
    const eventStart = `${year}${month}${day}T${hour.padStart(
      2,
      "0"
    )}${minute}00`;
    const eventEnd = `${year}${month}${day}T${(parseInt(hour) + 1)
      .toString()
      .padStart(2, "0")}${minute}00`; // Предполагаем, что событие длится 1 часа

    // Преобразование HTML в текстовое представление с добавлением \n
    const convertHtmlToText = (html) => {
      html = html.replace(/<ol>(.*?)<\/ol>/gis, (match, content) => {
        let listItems = content.match(/<li.*?>(.*?)<\/li>/gis);
        if (listItems) {
          return listItems
            .map(
              (item, index) =>
                `${index + 1}) ${item.replace(/<[^>]*>/g, "").trim()}`
            )
            .join("\\n");
        }
        return "";
      });

      html = html.replace(/<ul>(.*?)<\/ul>/gis, (match, content) => {
        let listItems = content.match(/<li.*?>(.*?)<\/li>/gis);
        if (listItems) {
          return listItems
            .map((item) => `• ${item.replace(/<[^>]*>/g, "").trim()}`)
            .join("\\n");
        }
        return "";
      });

      html = html.replace(/<br\s*\/?>/gi, "\\n");
      html = html.replace(/<p.*?>(.*?)<\/p>/gis, "$1\\n");
      html = html.replace(/<[^>]+>/g, "");
      return html.trim().replace(/(\n\s*){2,}/g, "\\n");
    };

    let description = convertHtmlToText(event.descr);

    if (event.moderator && event.moderator.length > 0) {
      description += `\\n\\nМодератор:\\n${event.moderator
        .map((mod) => mod.name)
        .join(", ")}`;
    }

    if (event.speakers && event.speakers.length > 0) {
      description += `\\n\\nСпикеры:\\n${event.speakers
        .map((sp) => sp.name)
        .join(", ")}`;
    }

    const icsContent =
      "BEGIN:VCALENDAR\n" +
      "VERSION:2.0\n" +
      "PRODID:-//Your Company//Your Product//EN\n" +
      "CALSCALE:GREGORIAN\n" +
      "BEGIN:VEVENT\n" +
      `UID:${uuidv4()}\n` +
      `DTSTAMP:${
        new Date().toISOString().replace(/[-:]/g, "").split(".")[0]
      }Z\n` +
      `DTSTART:${eventStart}\n` +
      `DTEND:${eventEnd}\n` +
      `SUMMARY:${event.name}\n` +
      `LOCATION:${event.place}\n` +
      `DESCRIPTION:${description}\n` +
      "ORGANIZER:mailto:organizer@example.com\n" +
      "END:VEVENT\n" +
      "END:VCALENDAR";

    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${event.name}.ics`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
  
export default ExportToIcs;