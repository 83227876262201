import React from "react";
import { Link } from "react-router-dom";
import telegram from "../../images/telegram-filled.svg";
import missionCard from "./images/mission-card-img.svg";
import missionCardMobile from "./images/mission-card-mobile.svg";
import styles from "./CardLink.module.css";

export default function CardLink() {
  return (
    <div className={styles.dark_card}>
      <div className={styles.card_text}>
        <Link className={styles.round_link}>
          <img
            alt="telegram"
            src={telegram}
            onClick={() => window.open("https://t.me/gradforum", "_blank")}
          />
        </Link>
        <p>
          Новости форума, актуальная и полезная информация строительного рынка
          России, уникальный контент, экспертные мнения, интервью лидеров
          строительной отрасли читайте в нашем Телеграм канале.
        </p>
      </div>

      <div className={styles.card_img}>
        <img src={missionCard} />
      </div>
      <div className={styles.card_img_mobile}>
        <img src={missionCardMobile} />
      </div>
    </div>
  );
}
