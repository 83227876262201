import React from "react";
import { Button } from "primereact/button";
import EventCardCreateForm from "../../components/EventCardCreateForm/EventCardCreateForm";
import { useParams, useNavigate } from "react-router";
import { useGetEventByIdQuery, useUpdateEventMutation } from "../../store/slices/apiSlice";
import styles from "./EventForm.module.css";

export default function EditEventForm() {
  const { eventId } = useParams();
  const navigate = useNavigate();

  const { data: event, isLoading: isLoadingEvent } = useGetEventByIdQuery(eventId);
  const [updateEvent, { isLoading: isUpdating }] = useUpdateEventMutation();

  const handleSave = async (updatedEvent) => {
    try {
      await updateEvent({ id: eventId, body: updatedEvent }).unwrap();
      navigate(-1);
    } catch (error) {
      console.error("Failed to update event:", error);
    }
  };

  if (isLoadingEvent) return <p>Загрузка...</p>;

  return (
    <div className={styles.card}>
      <div>
        <Button
          label="Назад"
          icon="pi pi-angle-left"
          className={styles.go_back_btn}
          onClick={() => navigate(-1)}
        />
      </div>
      <h2>Редактирование события</h2>
      <EventCardCreateForm
        initialData={event}
        onSave={handleSave}
        isLoading={isUpdating}
      />
    </div>
  );
}
