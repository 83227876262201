export const NavbarDropdownItems = [
  [
    {
      label: "Миссия",
      path: "/#mission",
      cName: "dropdown-link",
    },
    {
      label: "Площадка",
      path: "/#platform",
      cName: "dropdown-link",
    },
    {
      label: "Спикеры",
      path: "/#speakers",
      cName: "dropdown-link",
    },
  ],
  [
    {
      label: "Партнеры",
      path: "/#partners",
      cName: "dropdown-link",
    },
    {
      label: "Урбан-тур",
      path: "/urban-tour",
      cName: "dropdown-link",
    },
    {
      label: "Для гостей города",
      path: "/city-guests-info",
      cName: "dropdown-link",
    },
  ],
  [
    {
      label: "Для сми",
      path: "https://forms.yandex.ru/u/66b46d91c417f325dde2f5fa/",
      cName: "dropdown-link",
    },
    {
      label: "Вопрос-ответ",
      path: "/#qna",
      cName: "dropdown-link",
    },
    {
      label: "Контакты",
      path: "/#footer",
      cName: "dropdown-link",
    },
  ],
];
