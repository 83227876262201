import React, { useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import logo from "../../images/logo.svg";
import styles from "./ProfileMenu.module.css";
import { useNavigate } from "react-router-dom";
import { useGetProfilePhotoQuery } from "../../store/slices/apiSlice";
import useAdminService from "../../service/useAdminService"; // Импортируем сервис для проверки роли администратора

export default function ProfileMenu({ user, status, model = "" }) {
  const menuRight = useRef(null);
  const navigate = useNavigate();

  // Проверяем, является ли пользователь администратором
  const { isAdmin } = useAdminService();

  // Используем запрос для получения фото профиля
  const { data: profilePhoto } = useGetProfilePhotoQuery();

  // Преобразуем Blob в URL и кэшируем его с помощью useMemo
  const profileImageURL = useMemo(() => {
    return profilePhoto ? URL.createObjectURL(profilePhoto) : null;
  }, [profilePhoto]);

  // Очистка созданного URL после его использования
  React.useEffect(() => {
    return () => {
      if (profileImageURL) {
        URL.revokeObjectURL(profileImageURL);
      }
    };
  }, [profileImageURL]);

  const account = [
    {
      label: "Аккаунт",
      items: [
        {
          label: "Выйти",
          icon: "pi pi-sign-out",
          command: () => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            navigate("/");
          },
        },
      ],
    },
  ];

  /*---burger-menu---*/
  const [visible, setVisible] = useState(false);

  const adminMenuLinks = [
    {
      label: "Пользователи",
      command: () => {
        setVisible(false);
        navigate("/administration/users");
      },
    },
    {
      label: "Календарь событий",
      command: () => {
        setVisible(false);
        navigate("/administration/calendar");
      },
    },
    {
      label: "Заказы",
      command: () => {
        setVisible(false);
        navigate("/administration/orders");
      },
    },
    {
      label: "Личная",
      command: () => {
        setVisible(false);
        navigate("/profile/personal");
      },
    },
    {
      label: "Контактная",
      command: () => {
        setVisible(false);
        navigate("/profile/contacts");
      },
    },
    {
      label: "Профессиональная",
      command: () => {
        setVisible(false);
        navigate("/profile/professional");
      },
    },
    {
      label: "Мои события",
      command: () => {
        setVisible(false);
        navigate("/profile/events");
      },
    },
    {
      label: "Мои заказы",
      command: () => {
        setVisible(false);
        navigate("/profile/orders");
      },
    },
    {
      label: "На главную",
      command: () => {
        setVisible(false);
        navigate("/");
      },
    },
  ];

  const userMenuLinks = [
    {
      label: "Личная",
      command: () => {
        setVisible(false);
        navigate("/profile/personal");
      },
    },
    {
      label: "Контактная",
      command: () => {
        setVisible(false);
        navigate("/profile/contacts");
      },
    },
    {
      label: "Профессиональная",
      command: () => {
        setVisible(false);
        navigate("/profile/professional");
      },
    },
    {
      label: "Мои события",
      command: () => {
        setVisible(false);
        navigate("/profile/events");
      },
    },
    {
      label: "Мои заказы",
      command: () => {
        setVisible(false);
        navigate("/profile/orders");
      },
    },
    {
      label: "На главную",
      command: () => {
        setVisible(false);
        navigate("/");
      },
    },
  ];

  // Выбираем правильное меню в зависимости от роли пользователя
  const menuLinks = isAdmin ? adminMenuLinks : userMenuLinks;

  const start = (
    <>
      <div className={styles.desktop_logo}>
        <img
          alt="На главную"
          src={logo}
          height="56"
          className={styles.logo}
          onClick={() => navigate("/")}
        />
      </div>

      <div className={styles.sidebar}>
        <Button
          icon="pi pi-bars"
          text
          rounded
          size="large"
          style={{ color: "var(--highlight-mid)" }}
          onClick={() => setVisible(!visible)}
        />
        <Sidebar visible={visible} onHide={() => setVisible(false)}>
          <Menu
            model={menuLinks} // Используем динамическое меню
            className={styles.sidebar_content}
          />
        </Sidebar>
      </div>
    </>
  );

  /*---avatar---*/
  const end = (
    <div className={styles.container}>
      <div className={styles.line}>
        <h4>{user}</h4>
        <Avatar
          style={{ backgroundColor: "var(--highlight-mid)", cursor: "pointer" }}
          shape="circle"
          size="large"
          image={profileImageURL} // Устанавливаем URL для изображения
        >
          {!profileImageURL && (
            <i
              className="pi pi-user"
              style={{ fontSize: "1.45rem", color: "#ffffff" }}
              onClick={(event) => menuRight.current.toggle(event)}
            />
          )}
        </Avatar>
      </div>
    </div>
  );

  return (
    <>
      <Menubar
        start={start}
        end={end}
        style={{
          backgroundColor: "var(--form-light)",
          boxShadow: "0px 2px 0px var(--highlight-mid)",
        }}
      />
      <Menu
        model={account}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
      />
    </>
  );
}
