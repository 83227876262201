import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavbarDropdownItems } from "./NavbarDropdownItems";
import close from "../../images/close.svg";
import styles from "./NavbarDropdown.module.css";

function NavbarDropdown() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <div className={click ? styles.container.clicked : styles.container}>
      <ul onClick={handleClick} className={styles.dropdown_menu}>
        {NavbarDropdownItems.map((list) => {
          return (
            <div className={styles.column}>
              {list.map((item) => {
                return (
                  <li>
                    <Link
                      className={item.cName}
                      to={item.path}
                      onClick={() => setClick(false)}
                    >
                      {item.label}
                    </Link>
                  </li>
                );
              })}
            </div>
          );
        })}
      </ul>

      <div className={styles.btn}>
        <img src={close} onClick={handleClick} />
      </div>
    </div>
  );
}

export default NavbarDropdown;
