import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

import MainLayout from "../../components/Layout/MainLayout";
import SocialButtons from "../../components/SocialButtons/SocialButtons";
import CardLink from "../../components/CardLink/CardLink";

import opportunitiesData from "./OpportunitiesDatatable.json";
import person from "./images/person.svg";
import checkmark from "./images/checkmark.svg";
import minus from "./images/minus.svg";
import logOut from "./images/log-out.svg";
import telegram from "../../images/telegram-filled.svg";
import missionCard from "./images/mission-card-img.svg";
import missionCardMobile from "./images/mission-card-mobile.svg";

import styles from "./Opportunities.module.css";

export default function Opportunities() {
  const [opportunities, setOpportunities] = useState(opportunitiesData);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const vipTemplate = (data) => {
    return (
      <div className={styles.vip_cell_template}>
        <img
          alt={data.vip ? "+" : "-"}
          src={data.vip ? checkmark : minus}
          className={data.vip ? styles.checkmark : styles.minus}
        />
      </div>
    );
  };

  const businessTemplate = (data) => {
    return (
      <div className={styles.business_cell_template}>
        <img
          alt={data.business ? "+" : "-"}
          src={data.business ? checkmark : minus}
          className={data.business ? styles.checkmark : styles.minus}
        />
      </div>
    );
  };

  const labelTemplate = (data) => {
    return (
      <p data-rh={ data.hint }
        data-rh-at="Top"
        style={{cursor: "pointer"}}>
        {data.name}{" "}
      </p>
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="* — по предварительной записи"
          colSpan={1}
          className={styles.footer_row}
          footerStyle={{ textAlign: "left" }}
        />
        <Column
          footer="120 000₽"
          className={styles.highlight}
          footerStyle={{ textAlign: "center" }}
        />
        <Column
          footer="50 000₽"
          className={styles.highlight}
          footerStyle={{ textAlign: "center" }}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <MainLayout>
      <div className={styles.container} id="opportunities">
        <div className={styles.column_heading}>
          <span className={styles.heading}>возможности</span>
          <span className={styles.heading_desktop}>участников форума</span>

          <span className={styles.heading_mobile}>участников</span>
          <span className={styles.heading_mobile}>форума</span>
        </div>

        <div className={styles.picture}>
          <img src={person} />
        </div>

        <SocialButtons />

        <div className={styles.table_wrapper}>
          <DataTable
            value={opportunities}
            className={styles.custom_datatable}
            footerColumnGroup={footerGroup}
          >
            <Column
              field="name"
              header="Возможность"
              className={styles.name_cell}
              body={(row) => labelTemplate(row)}
            />
            <Column
              field="vip"
              header="Вип"
              body={vipTemplate}
              className={styles.vip_cell}
              alignHeader="center"
            />
            <Column
              field="business"
              header="Бизнес"
              body={businessTemplate}
              className={styles.business_cell}
              alignHeader="center"
            />
          </DataTable>
        </div>

        <div className={styles.controls}>
          <div className={styles.btn_wrapper}>
            <span
              onClick={() => navigate("/profile/orders/create")}
              className={styles.btn}
            >
              Стать Вип-участником
            </span>
            <span
              onClick={() => navigate("/profile/orders/create")}
              className={styles.btn}
            >
              Стать Бизнес-участником
            </span>
          </div>
          <div className={styles.link_wrapper}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/documents/инструкция_для_регистрации_участника_на_форум_через_сайт_gradforum.pdf"
            >
              Инструкция по покупке билета
            </Link>
            <img alt="выйти" src={logOut} />
          </div>
        </div>
      </div>

      <div className={styles.card_container}>
          <div
            className={styles.card}
            onClick={() => window.open("https://t.me/gradforum", "_blank")}
          >
            <CardLink />
          </div>
        </div>
    </MainLayout>
  );
}
