import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useGetAdminOrdersQuery } from "../../store/slices/apiSlice"; 
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import getStatusLabel from "../../hooks/useCheckStatus";
import styles from "./OrdersDatable.module.css";

export default function OrdersDatable() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0); // Нумерация страниц начинается с 0
  const [rows, setRows] = useState(100); // Количество записей на странице
  const [totalRecords, setTotalRecords] = useState(0);

  const { data, error, isLoading } = useGetAdminOrdersQuery({
    pageNumber: page + 1, // API использует 1-ную нумерацию страниц
    pageSize: rows,
  });

  const orders = data?.items || [];

  const [selectedOrder, setSelectedOrder] = useState(null);

  // Обновление количества записей при получении новых данных
  useEffect(() => {
    if (data) {
      setTotalRecords(data.totalCount);
    }
  }, [data]);

  // Форматирование статуса
  const statusBodyTemplate = (order) => {
    return getStatusLabel(order.status);
  };

  // Обработка выбора строки
  const handleRowSelection = (e) => {
    setSelectedOrder(e.value);
    navigate(`./${e.value.id}`);
  };

  // Обработка изменения страницы
  const onPageChange = (event) => {
    setPage(event.page);
    setRows(event.rows);
  };

  return (
    <div className={styles.card}>
      <h2>Заказы</h2>
      {/* Обработка состояний загрузки и ошибок */}
      {isLoading && (
        <div className={styles.loading}>
          <ProgressSpinner />
        </div>
      )}
      {error && (
        <Message severity="error" text="Ошибка загрузки данных" />
      )}
      {!isLoading && !error && (
        <DataTable
          value={orders}
          removableSort
          className={styles.datatable}
          selectionMode="single"
          selection={selectedOrder}
          onSelectionChange={(e) => handleRowSelection(e)}
          dataKey="id"
          paginator
          rows={rows}
          totalRecords={totalRecords} // Устанавливаем общее количество записей
          onPage={onPageChange}
          first={page * rows} // Начало страницы
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rowsPerPageOptions={[100, 200, 500]}
        >
          <Column field="id" header="№" sortable/>
          <Column field="ticket" header="Тип билета" sortable />
          <Column field="customerName" header="ФИО" sortable />
          <Column field="customerCompany" header="Компания" sortable />
          <Column
            field="status"
            header="Статус"
            body={statusBodyTemplate}
            sortable
          />
        </DataTable>
      )}
    </div>
  );
}
