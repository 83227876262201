import React, { useEffect } from "react";
import styles from "./ProfInfo.module.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

export default function ProfInfo({ formData = {}, onValueChange }) {
  const spheres = [
    { name: "Органы государственной власти" },
    { name: "Урбанистика" },
    { name: "Недвижимость" },
    { name: "Финансовый сектор" },
    { name: "Маркетинг" },
    { name: "Образование" },
    { name: "Туризм" },
    { name: "Строительство" },
    { name: "Промышленность" },
    { name: "IT и телекоммуникации" },
    { name: "Торговля и коммерция" },
    { name: "Сельское хозяйство" },
    { name: "Медицина" },
];

  useEffect(() => {
    
  console.log("formData",formData)
  },[formData])

  return (
    <div className={styles.info}>
      <div className={styles.item_three}>
        <div className={styles.column}>
          <div className={styles.column}>
            <label htmlFor="inn">
              ИНН компании <span>*</span>
            </label>
            <InputText
              id="inn"
              value={formData.companyInn || ""}
              onChange={(e) => onValueChange("companyInn", e.target.value)}
              aria-describedby="inn"
            />
          </div>
          <div className={styles.check_inn}>
            <input
              id="inn_check"
              type="checkbox"
              onChange={(e) =>
                onValueChange("companyInn", e.target.checked ? "" : formData.companyInn)
              }
              checked={!formData.companyInn}
            />
            <label htmlFor="inn_check">У компании нет ИНН</label>
          </div>
        </div>

        <div className={styles.column}>
          <label htmlFor="company_name">
            Название компании (на русском) <span>*</span>
          </label>
          <InputText
            id="company_name"
            value={formData.company || ""}
            onChange={(e) => onValueChange("company", e.target.value)}
            aria-describedby="company_name"
          />
        </div>
      </div>

      <div className={styles.item_two}>
        <div className={styles.column}>
          <label id="sphere">
            Сфера деятельности компании <span>*</span>
          </label>
          <Dropdown
            aria-labelledby="sphere"
            value={formData.companyIndustry || ""}
            onChange={(e) => onValueChange("companyIndustry", e.value)}
            options={spheres}
            optionLabel="name"
            placeholder="Выберите сферу"
            className={styles.dropdown_text}
          />
        </div>

        <div className={styles.column}>
          <label htmlFor="post">
            Должность <span>*</span>
          </label>
          <InputText
            id="post"
            value={formData.jobTitle || ""}
            onChange={(e) => onValueChange("jobTitle", e.target.value)}
            aria-describedby="post"
          />
        </div>
      </div>

      <div className={styles.item_two}>
        <div className={styles.column_item_one}>
          <label id="user_sphere">
            Ваша сфера деятельности <span>*</span>
          </label>
          <Dropdown
            aria-labelledby="user_sphere"
            value={formData.userIndustry || ""}
            onChange={(e) => onValueChange("userIndustry", e.value)}
            options={spheres}
            optionLabel="name"
            placeholder="Выберите сферу"
            className={styles.dropdown_text}
          />
        </div>
      </div>
    </div>
  );
}
