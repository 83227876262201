import React, { useEffect, useState, useRef } from "react";
import styles from "./ProfInfoCard.module.css";
import ProfInfo from "../../components/ProfInfo/ProfInfo";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useGetCompanyDataQuery, useUpdateCompanyDataMutation } from "../../store/slices/apiSlice";

export default function ProfInfoCard() {
  const toast = useRef(null);
  const { data: companyData, isLoading, isError } = useGetCompanyDataQuery();
  const [updateCompanyData] = useUpdateCompanyDataMutation();
  
  const [formData, setFormData] = useState({
    companyInn: "",
    company: "",
    companyIndustry: null,
    jobTitle: "",
    userIndustry: null,
  });

  useEffect(() => {
    if (companyData) {
      setFormData({
        companyInn: companyData.companyInn || "",
        company: companyData.company || "",
        companyIndustry: { name: companyData.companyIndustry } || null,
        jobTitle: companyData.jobTitle || "",
        userIndustry: { name: companyData.userIndustry } || null,
      });
    }
  }, [companyData]);

  const handleSave = async () => {
    try {
      const payload = {
        ...formData,
        companyIndustry: formData.companyIndustry?.name || "",
        userIndustry: formData.userIndustry?.name || "",
      };

      await updateCompanyData(payload).unwrap();
      toast.current.show({ severity: 'success', summary: 'Успех', detail: 'Данные успешно сохранены', life: 3000 });
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Произошла ошибка при сохранении данных. Проверьте заполнены ли все данные', life: 3000 });
    }
  };

  const handleValueChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className={styles.card}>
      <h2>Профессиональная информация</h2>
      <ProfInfo formData={formData} onValueChange={handleValueChange} />
      <Button label="Сохранить" className={styles.save_btn} onClick={handleSave} />
      <Toast ref={toast} />
    </div>
  );
}
