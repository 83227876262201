import React from "react";
import { Button } from "primereact/button";
import UsersDatatable from "../UsersDatatable/UsersDatatable";
import { useNavigate } from "react-router";
import styles from "./UsersDatatableCard.module.css";

export default function UsersDatatableCard() {
  const navigate = useNavigate();

  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <h2>Пользователи</h2>
        <Button
          label="Новый пользователь"
          icon="pi pi-plus"
          className={styles.custom_btn}
          onClick={() => {
            navigate("./create");
          }}
        />
      </div>
      <UsersDatatable />
    </div>
  );
}
