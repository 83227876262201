import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { redirect } from "react-router-dom";

const url = `https://gradforum.ru/api`;
const buildApiUrl = (endpoint) => `${url}/${endpoint}`;

let isRefreshing = false;    // Flag to indicate if token refresh is in progress
let refreshPromise = null;   // To hold the refresh token promise


/* ---------- BASE AUTH QUERY ---------- */
const baseQuery = fetchBaseQuery({
  baseUrl: url,
  prepareHeaders: async headers => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    if (!headers.has('IsFile')) {
      headers.set('Content-Type', 'application/json');
    }

    headers.set('Accept', 'application/json');
    return headers
  }
});


const baseQueryWithLogout = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // If we get a 401 response, remove the accessToken from localStorage and handle redirection
  if (result.error && result.error.status === 401) {
    localStorage.removeItem('accessToken');
    // Optionally, you can redirect to the login page after token removal
    redirect('/login');
  }

  return result;
};



/* ---------- QUERIES SLICE ---------- */
export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithLogout,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getProfilePhoto: builder.query({
      query: () => '/profile/photo',
      responseHandler: async (response) => {
        // Проверка, если ответ успешен, обрабатываем его как Blob
        if (response.ok) {
          return await response.blob();
        }
        throw new Error('Failed to fetch image');
      },
      // Указываем, что не нужно пытаться автоматически парсить ответ
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        try {
          const { data } = await cacheDataLoaded;
          updateCachedData(() => {
            return data;
          });
        } catch {}
      },
    }),
    get: builder.query({
      queryFn: async (endpoint, _quryApi, _options, fetchBaseQuery) => {
        let res = await fetchBaseQuery(`${endpoint}`);
        if (res.data) {
          console.log("SUCCESS:", res);
          return { data: res.data };
        } else {
          res.endpointAddress = endpoint;
          console.log("ERROR:", res);
          return { error: res };
        }
      },
    }),
    add: builder.mutation({
      query: ({ endpoint, id, body }) => ({
        url: `${endpoint}${id}`,
        method: "POST",
        body: JSON.stringify(body),
      }),
    }),
    update: builder.mutation({
      query: ({ endpoint, id, body }) => ({
        url: `${endpoint}${id}`,
        method: "PUT",
        body: JSON.stringify(body),
      }),
    }),
    remove: builder.mutation({
      query: ({ endpoint, id }) => ({
        url: `${endpoint}${id}`,
        method: "DELETE",
      }),
    }),
    registerUser: builder.mutation({
      query: (body) => ({
        url: `/auth/register`,
        method: 'POST',
        body: JSON.stringify(body),
      })
    }),
    getCode: builder.mutation({
      query: (phone) => ({
        url: `/auth/request-code?phoneNumber=${phone}`,
        method: 'GET',
      }),
    }),
    saveContactData: builder.mutation({
      query: (contactData) => ({
        url: "/profile/contact-data",
        method: "PUT",
        body: contactData,
      }),
    }),
    getProfileData: builder.query({
      query: () => '/profile',
    }),
    getContactData: builder.query({
      query: () => '/profile/contact-data',
    }),
    getCompanyData: builder.query({
      query: () => '/profile/company-data',
    }),
    updateCompanyData: builder.mutation({
      query: (companyData) => ({
        url: '/profile/company-data',
        method: 'PUT',
        body: companyData,
      }),
    }),
    setupSession: builder.mutation({
      query: (body) => ({
        url: `/auth/login`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          credentials: 'include',
        },
        body: JSON.stringify(body),
      })
    }),
    getOrderById: builder.query({
      query: (orderId) => `/profile/orders/${orderId}`,
    }),
    createOrder: builder.mutation({
      query: (orderData) => ({
        url: "/profile/orders",
        method: "POST",
        body: orderData,
      }),
    }),
    updateOrder: builder.mutation({
      query: ({ orderId, orderData }) => ({
        url: `/profile/orders/${orderId}`,
        method: "PUT",
        body: orderData,
      }),
    }),
    removeOrder: builder.mutation({
      query: (orderId) => ({
        url: `/profile/orders/${orderId}/cancel`,
        method: "PUT"
      }),
    }),
    getFavoriteEvents: builder.query({
      query: () => '/profile/events/favorites',
    }),
    addEventToFavorites: builder.mutation({
      query: (eventId) => ({
        url: `/profile/events/favorites/${eventId}`,
        method: 'POST',
      }),
    }),
    removeEventFromFavorites: builder.mutation({
      query: (eventId) => ({
        url: `/profile/events/favorites/${eventId}`,
        method: 'DELETE',
      }),
    }),
    getEvents: builder.query({
      query: () => '/events',
    }),
    getUsers: builder.query({
      query: () => "/admin/users",
    }),
    getUserById: builder.query({
      query: (id) => `/admin/users/${id}`,
    }),
    updateUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/users/${id}`,
        method: "PUT",
        body,
      }),
    }),
    createUser: builder.mutation({
      query: (userData) => ({
        url: '/admin/users',
        method: 'POST',
        body: userData,
      }),
    }),
    removeUser: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/users/${id}`,
        method: "DELETE",
      }),
    }),
    getProfileStatus: builder.query({
      query: () => '/profile/status',
    }),
    setAdminLevel: builder.mutation({
      query: ({ id, adminLevel }) => ({
        url: `/admin/users/${id}/assign-admin`,
        method: "PUT",
        body: adminLevel,
      }),
    }),
    getAdminEvents: builder.query({
      query: () => "/admin/events",
    }),
    addEvent: builder.mutation({
      query: ({ body }) => ({
        url: "/admin/events",
        method: 'POST',
        body,
      }),
    }),
    getEventById: builder.query({
      query: (id) => `/admin/events/${id}`,
    }),
    updateEvent: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/events/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `/admin/events/${id}`,
        method: 'DELETE',
      }),
    }),
    getAdminOrders: builder.query({
      query: ({ pageNumber = 1, pageSize = 10 }) => `/admin/orders?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    }),
    getAdminOrderById: builder.query({
      query: (id) => `/admin/orders/${id}`,
    }),
    startProcessingOrder: builder.mutation({
      query: (orderId) => ({
        url: `/admin/orders/${orderId}/start-processing`,
        method: 'PUT',
      }),
    }),
    approveOrder: builder.mutation({
      query: (orderId) => ({
        url: `/admin/orders/${orderId}/approve`,
        method: 'PUT',
      }),
    }),
    rejectOrder: builder.mutation({
      query: (orderId) => ({
        url: `/admin/orders/${orderId}/reject`,
        method: 'PUT',
      }),
    }),
    uploadFileAdmin: builder.mutation({
      query: ({ orderId, file }) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `admin/orders/${orderId}/files`,
          method: 'POST',
          body: file,
          headers:{ "IsFile": ""}
        };
      },
    }),
    listFilesAdmin: builder.query({
      query: (orderId) => `admin/orders/${orderId}/files`,
    }),
    downloadFileAdmin: builder.mutation({
      query: ({ orderId, fileName }) => ({
        url: `admin/orders/${orderId}/files/${fileName}`,
        method: 'GET',
        responseHandler: (response) => response.blob(), // Для получения файла в виде blob
      }),
    }),
    deleteFileAdmin: builder.mutation({
      query: ({ orderId, fileName }) => ({
        url: `admin/orders/${orderId}/files/${fileName}`,
        method: 'DELETE',
      }),
    }),
    listFilesUser: builder.query({
      query: (orderId) => `profile/orders/${orderId}/files`,
    }),
    downloadFileUser: builder.mutation({
      query: ({ orderId, fileName }) => ({
        url: `profile/orders/${orderId}/files/${fileName}`,
        method: 'GET',
        responseHandler: (response) => response.blob(), // Для получения файла в виде blob
      }),
    }),
  }),
});


export const {
  useGetQuery,
  useAddMutation,
  useUpdateMutation,
  useRemoveMutation,
  useRegisterUserMutation,
  useGetCodeMutation,
  useSetupSessionMutation,
  useGetProfilePhotoQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useGetProfileDataQuery,
  useGetContactDataQuery,
  useSaveContactDataMutation,
  useGetCompanyDataQuery,
  useUpdateCompanyDataMutation,
  useGetFavoriteEventsQuery,
  useAddEventToFavoritesMutation,
  useRemoveEventFromFavoritesMutation,
  useGetEventsQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useGetProfileStatusQuery,
  useGetOrderByIdQuery,
  useRemoveOrderMutation,
  useRemoveUserMutation,
  useSetAdminLevelMutation,
  useGetAdminEventsQuery,
  useAddEventMutation,
  useGetEventByIdQuery,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useGetAdminOrdersQuery,
  useGetAdminOrderByIdQuery,
  useStartProcessingOrderMutation,
  useApproveOrderMutation,
  useRejectOrderMutation,
  useUploadFileAdminMutation,
  useDownloadFileAdminMutation,
  useListFilesAdminQuery,
  useDeleteFileAdminMutation,
  useListFilesUserQuery,
  useDownloadFileUserMutation,
} = apiSlice;
export { url, buildApiUrl as buildUrl };
