import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ConfirmCode.module.css";
import { InputOtp } from "primereact/inputotp";
import { Button } from "primereact/button";

import { useSetupSessionMutation } from "../../store/slices/apiSlice";
import { useGetCodeMutation } from "../../store/slices/apiSlice";
import Loader from "../Loader/Loader";

export default function ConfirmCode({ phone }) {
  const navigate = useNavigate();
  const [userCode, setUserCode] = useState();
  const [invalidCode, setInvalidCode] = useState(false);
  const [refrechTimer, setRefrechTimer] = useState(true);

  const [setupSession, { isLoading: sessionLoading }] =
    useSetupSessionMutation();
  const [getCode, { isLoading: codeLoading }] = useGetCodeMutation();

  const loginUser = async () => {
    setRefrechTimer(true);
    let userPhone = phone;
    if (userPhone.length !== 11) userPhone = "7" + userPhone;

    const response = await setupSession({
      number: userPhone,
      code: userCode,
    });
    console.log(response);

    if (!response.error) {
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      setInvalidCode(false);
      navigate("/profile");
    } else {
      setInvalidCode(true);
    }
  };

  const refreshCode = async () => {
    let userPhone = phone;
    if (userPhone.length !== 11) userPhone = "7" + userPhone;

    setRefrechTimer(true);
    const response = await getCode(userPhone);
    console.log("-----response data", response.data);
    console.log("-----response error", response.error);
  };

  setTimeout(function () {
    setRefrechTimer(false);
  }, 50000);

  if (sessionLoading || codeLoading) return <Loader />;
  else
    return (
      <div className={styles.submit_form}>
        <h4>Введите код подтверждения из СМС</h4>
        <InputOtp
          value={userCode}
          onChange={(e) => setUserCode(e.value)}
          onFocus={() => setInvalidCode(false)}
          length={6}
          //integerOnly
          autoFocus
        />
        {invalidCode ? <p className={styles.error}>Неправильный код</p> : null}
        <div className={styles.btn_wrapper}>
          <Button
            label="Подтвердить"
            className={styles.custom_btn}
            onClick={() => loginUser()}
          />
        </div>
        <div className={styles.text}>
          <p>Не пришел СМС-код?</p>
          <Button
            label="Отправить повторно"
            link
            className="custom_link_btn"
            onClick={() => refreshCode()}
            disabled={refrechTimer}
          />
        </div>
      </div>
    );
}
