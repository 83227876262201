import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import styles from "./SpeakerCard.module.css";

export default function SpeakerCard({ speaker, img, imgMobile }) {
  const [visible, setVisible] = useState(false);

  return (
    <div className={styles.card}>
      <div className={styles.img_wrapper}>
        <img src={img} />
      </div>
      <div
        className={styles.img_wrapper_mobile}
      >
        <img src={imgMobile} />
      </div>
      <div className={styles.text}>
        <div className={styles.name}>
          <span>{speaker.name}</span>
          <span>{speaker.surname}</span>
        </div>
        <span>{speaker.descr}</span>
      </div>

      <Dialog
        header={speaker.name + " " + speaker.surname}
        visible={false}
        headerStyle={{ textTransform: "uppercase" }}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className={styles.dialog}>
          <div className={styles.dialog_speaker}>
            <div className={styles.img_wrapper}>
              <img src={img} />
            </div>
            <div className={styles.img_wrapper_mobile}>
              <img src={imgMobile} />
            </div>
          </div>
          <div className={styles.dialog_descr}>
            <span>{speaker.descr}</span>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
