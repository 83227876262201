  import React, { useEffect, useRef, useState } from 'react';
  import styles from './PersonalInfoCard.module.css';
  import { Button } from 'primereact/button';
  import { Toast } from 'primereact/toast';
  import PersonalInfo from "../../components/PersonalInfo/PersonalInfo";
  import { useSelector } from "react-redux";
  import {
    buildUrl,
    useGetQuery,
    useUpdateMutation,
  } from "../../store/slices/apiSlice";


  export default function PersonalInfoCard() {

    const toast = useRef(null); // Создаем реф для Toast
    const [personalData, setPersonalData] = useState({
      lastName: '',
      firstName: '',
      secondName: '',
      birthDate: null,
      gender: '',
      country: '',
      city: '',
    });

    const [updateData, { isLoading }] = useUpdateMutation();
    const profileEP = useSelector((state) => state.endpoints.profile);

    let { data: remotePersonalData } = useGetQuery(`${buildUrl(profileEP)}/personal-data`, {
      refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
      if (remotePersonalData) {
        let birthDate = null;
        
        if (remotePersonalData.birthDate) {
          birthDate = new Date(remotePersonalData.birthDate);
          if (birthDate.getFullYear() === 1)
            birthDate = null;
        }
        const formattedData = {
          ...remotePersonalData,
          birthDate: birthDate,
        };
        setPersonalData(formattedData);
      }
    }, [remotePersonalData]);

    const handleValueChange = (newData) => {
      setPersonalData(newData);
    };

    const handleSave = async () => {
      try {
        const formattedBirthDate = personalData.birthDate
          ? `${personalData.birthDate.getFullYear()}-${String(personalData.birthDate.getMonth() + 1).padStart(2, '0')}-${String(personalData.birthDate.getDate()).padStart(2, '0')}`
          : null;

        await updateData({
          endpoint: `${profileEP}/personal-data`,
          id: ``,
          body: {
            ...personalData,
            birthDate: formattedBirthDate,
          },
        }).unwrap();
        
        toast.current.show({ severity: 'success', summary: 'Успех', detail: 'Данные успешно сохранены', life: 3000 });
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Произошла ошибка при сохранении данных. Проверьте заполнены ли все данные', life: 3000 });
      }
    };

    return (
      <div className={styles.card}>
        <h2>Личная информация</h2>
        <PersonalInfo value={personalData} onValueChange={handleValueChange} />
        <Button label="Сохранить" className={styles.save_btn} onClick={handleSave} disabled={isLoading} />
        <Toast ref={toast} /> {/* Добавляем Toast в JSX */}
      </div>
    );
  }
