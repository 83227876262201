import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactHintFactory from "react-hint";

/*---pages--- */
import Home from "./pages/Home/Home";
import Opportunities from "./pages/Opportunities/Opportunities";
import Program from "./pages/Program/Program";
import UrbanTour from "./pages/UrbanTour/UrbanTour";
import CityGuestsInfo from "./pages/CityGuestsInfo/CityGuestsInfo";
import SpeakerPage from "./pages/SpeakerPage/SpeakerPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import AdminProfilePage from "./pages/AdminProfilePage/AdminProfilePage";

/*---modules--- */
import PersonalInfoBlock from "./modules/PersonalInfoBlock/PersonalInfoBlock";
import ProfInfoCard from "./modules/ProfInfoCard/ProfInfoCard";
import ProfileEvents from "./modules/ProfileEvents/ProfileEvents";
import ContactInfoCard from "./modules/ContactInfoCard/ContactsInfoCard";
import UsersDatatableCard from "./modules/UsersDatatableCard/UsersDatatableCard";
import CreateUserCard from "./modules/UserForm/CreateUserCard";
import EditUserCard from "./modules/UserForm/EditUserCard";
import CalendarCard from "./modules/CalendarCard/CalendarCard";
import CreateEventForm from "./modules/EventForm/CreateEventForm";
import EditEventForm from "./modules/EventForm/EditEventForm";

import OrderBlock from "./modules/OrdersBlock/OrdersBlock";
import OrdersDatable from "./modules/OrdersDatable/OrdersDatable";
import OrderDetails from "./modules/OrderDetails/OrderDetails";
import UserOrderDetailsCard from "./modules/UserOrderDetailsCard/UserOrderDetailsCard";
import CreateUserOrder from "./modules/CreateUserOrder/CreateUserOrder";

/*---styles--- */
import "./App.css";
import "./styles.css";
import ScrollToAnchor from "./hooks/ScrollToAnchor";

const ReactHint = ReactHintFactory(React);
function App() {
  return (
    <div>
      <ReactHint autoPosition events />
      <Router>
        <ScrollToAnchor/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/opportunities" element={<Opportunities />} />
          <Route exact path="/program" element={<Program />} />
          <Route exact path="/urban-tour" element={<UrbanTour />} />
          <Route exact path="/city-guests-info" element={<CityGuestsInfo />} />
          <Route exact path="/speaker/:fullname" element={<SpeakerPage />} />

          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/registration" element={<RegistrationPage />} />

          <Route exact path="/profile" element={<ProfilePage />}>
            <Route path="personal" element={<PersonalInfoBlock />} />
            <Route path="contacts" element={<ContactInfoCard />} />
            <Route path="professional" element={<ProfInfoCard />} />
            <Route path="events" element={<ProfileEvents />} />
            <Route path="orders" element={<OrderBlock />} />
            {/* <Route path="orders/create" element={<CreateUserOrder />} /> */}
            <Route path="orders/create" element={<CreateUserOrder />}>
              <Route path=":orderId" element={<CreateUserOrder />} />
            </Route>
          </Route>

          <Route exact path="/administration" element={<AdminProfilePage />}>
            <Route path="users" element={<UsersDatatableCard />} />
            <Route path="users/create" element={<CreateUserCard />} />
            <Route path="users/edit" element={<EditUserCard />}>
              <Route path=":id" element={<EditUserCard />} />
            </Route>
            <Route path="calendar" element={<CalendarCard />} />
            <Route path="calendar/create" element={<CreateEventForm />} />
            <Route path="calendar/edit" element={<EditEventForm />}>
              <Route path=":eventId" element={<EditEventForm />} />
            </Route>
            <Route path="orders" element={<OrdersDatable />} />
            <Route path="orders/:orderId" element={<OrderDetails />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
