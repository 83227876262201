import React from "react";
import styles from "./ContactInfo.module.css";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { MultiSelect } from "primereact/multiselect";

export default function ContactInfo({ contactData = {}, setContactData }) {
  const messengers = [
    { name: "Telegram", code: "telegram" },
    { name: "Viber", code: "viber" },
    { name: "WhatsApp", code: "whatsapp" },
    { name: "VK", code: "vk" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMessengersChange = (e) => {
    setContactData((prevState) => ({
      ...prevState,
      messengers: e.value.map((messenger) => messenger.code),
    }));
  };

  return (
    <div className={styles.info}>
      <div className={styles.item_two}>
        <div className="flex flex-column gap-2">
          <label htmlFor="phone">
            Телефон <span>*</span>
          </label>
          <InputMask
            id="phone"
            name="phoneNumber"
            mask="+7 (999) 999-99-99"
            placeholder="+7 (999) 111-11-11"
            value={contactData.phoneNumber || ""}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="email">
            Email <span>*</span>
          </label>
          <InputText
            id="email"
            name="email"
            aria-describedby="email"
            value={contactData.email || ""}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={styles.item_two}>
        <div className="flex flex-column gap-2">
          <label htmlFor="messengers">Какими мессенджерами пользуетесь?</label>
          <MultiSelect
            value={contactData.messengers?.map((code) =>
              messengers.find((messenger) => messenger.code === code)
            ) || []}
            options={messengers}
            onChange={handleMessengersChange}
            optionLabel="name"
            placeholder="Выберите мессенджеры"
            className="w-full"
            display="chip"
          />
        </div>
      </div>
    </div>
  );
}
