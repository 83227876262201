export const Speakers = [
  [
    [
      {
        id: 1,
        name: "Константин",
        surname: "Михайлик",
        descr:
          "Заместитель министра строительства и жилищно-коммунального хозяйства Российской Федерации",
        info: "",
        img: "mihailik.png",
        imgMobile: "mihailik-mobile.png",
      },
      {
        id: 2,
        name: "Александр",
        surname: "Моор",
        descr: "Губернатор Тюменской области",
        info: "",
        img: "moor.png",
        imgMobile: "moor-mobile.png",
      },
    ],
    [
      {
        id: 3,
        name: "Сергей",
        surname: "Шустов",
        descr: "Заместитель Губернатора Тюменской области",
        info: "",
        img: "shustov.png",
        imgMobile: "shustov-mobile.png",
      },
      {
        id: 4,
        name: "Павел",
        surname: "Перевалов",
        descr: "Начальник Главного управления строительства Тюменской области",
        info: "",
        img: "perevalov.png",
        imgMobile: "perevalov-mobile.png",
      },
    ],
  ],
  [
    [
      {
        id: 5,
        name: "Елена",
        surname: "Звонарёва",
        descr:
          "Заместитель руководителя Центра компетенций Департамента строительства Москвы, советник Министра строительства РФ",
        info: "",
        img: "zvonareva.png",
        imgMobile: "zvonareva-mobile.png",
      },
      {
        id: 6,
        name: "Максим",
        surname: "Афанасьев",
        descr: "Глава города Тюмени",
        info: "",
        img: "afanasev.png",
        imgMobile: "afanasev-mobile.png",
      },
    ],
    [
      {
        id: 7,
        name: "Сергей",
        surname: "Козлов",
        descr: "Руководитель Центра урбанистики ТюмГУ",
        info: "",
        img: "kozlov.png",
        imgMobile: "kozlov-mobile.png",
      },
      {
        id: 8,
        name: "Виктор",
        surname: "Рейн",
        descr:
          "Заместитель председателя Комитета по экономической политике и природо-пользованию Тюменской областной Думы",
        info: "",
        img: "rhein.png",
        imgMobile: "rhein-mobile.png",
      },
    ],
  ],
  [
    [
      {
        id: 9,
        name: "Дмитрий",
        surname: "Трофимов",
        descr:
          "Управляющий директор департамента развития корпоративного бизнеса, ПАО Сбербанк",
        info: "",
        img: "trofimov.png",
        imgMobile: "trofimov-mobile.png",
      },
      {
        id: 10,
        name: "Алексей",
        surname: "Половников",
        descr:
          "ЗГД по строительству ООО «АСПЭК ГРУПП», директор ООО «АСПЭК-Тюмень»",
        info: "",
        img: "polovnikov.png",
        imgMobile: "polovnikov-mobile.png",
      },
    ],
    [
      {
        id: 11,
        name: "Тимур",
        surname: "Абдуллаев",
        descr: "Руководитель архитектурного бюро ARCHINFORM",
        info: "",
        img: "abdullaev.png",
        imgMobile: "abdullaev-mobile.png",
      },
      {
        id: 12,
        name: "Михаил",
        surname: "Зайчук",
        descr:
          "Руководитель архитектурной группы rTIM, Rocket Group, Выпускник программы Архитекторы.рф",
        info: "",
        img: "zaichuk.png",
        imgMobile: "zaichuk-mobile.png",
      },
    ],
  ],
  [
    [
      {
        id: 13,
        name: "Георгий",
        surname: "Тюгаев",
        descr: "Генеральный директор HADAA Architects",
        info: "",
        img: "tugaev.png",
        imgMobile: "tugaev-mobile.png",
      },
      {
        id: 14,
        name: "Антон",
        surname: "Айсин",
        descr: "Руководитель отдела аналитики CM International ",
        info: "",
        img: "icin.png",
        imgMobile: "icin-mobile.png",
      },
    ],
    [
      {
        id: 15,
        name: "Ольга",
        surname: "Романец",
        descr:
          "Член правления Ассоциации индустриальных парков России, директор по развитию i.moscow, Правительство Москвы",
        info: "",
        img: "romanets.png",
        imgMobile: "romanets-mobile.png",
      },
      {
        id: 16,
        name: "Юлия",
        surname: "Шишалова",
        descr:
          "Архитектурный журналист, главный редактор медиа «Проект Россия»",
        info: "",
        img: "shishalova.png",
        imgMobile: "shishalova-mobile.png",
      },
    ],
  ],
];
