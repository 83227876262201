import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import { PartnersService } from "../../service/PartnersService";
import styles from "./PartnersCarousel.module.css";

function PartnersCarousel() {
  const [partners, setPartners] = useState([]);

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "820px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 2,
      numScroll: 1,
    },
  ];

  const [partnersService, setPartnersService] = useState(new PartnersService());

  useEffect(() => {
    partnersService.getPartners().then((data) => setPartners(data));
  }, [partnersService]);

  const partnerTemplate = (partner) => {
    return (
      <div className={styles.list_item}>
        <Link to={partner.linkTo} target="_blank" rel="noopener noreferrer">
          <img
            className={styles.logo}
            src={require(`./images/${partner.src}`)}
            alt={partner.alt}
          />
        </Link>
      </div>
    );
  };

  return (
    <Carousel
      value={partners}
      numScroll={1}
      numVisible={4}
      responsiveOptions={responsiveOptions}
      itemTemplate={partnerTemplate}
    />
  );
}

export default PartnersCarousel;
