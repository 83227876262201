import React, { useEffect, useLayoutEffect, useReducer, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../components/Layout/MainLayout";
import SocialButtons from "../../components/SocialButtons/SocialButtons";
import PartnersCarousel from "../../components/PartnersCarousel/PartnersCarousel";
import CardLink from "../../components/CardLink/CardLink";
import gsap from "gsap";

import illustration from "./images/illustration.svg";
import illustrationMobile from "./images/illustration-mobile.svg";

import brusnikaLogo from "./images/brusnika.svg";
import missionSide from "./images/mission-side-img.svg";

import office from "./images/office.png";
import construction from "./images/construction.png";

import ExhibitionMap from "../../modules/ExhibitionMap/ExhibitionMap";
import ExhibitionMapMobile from "../../modules/ExhibitionMap/ExhibitionMapMobile";

import ParticipationFormatCard from "../../components/ParticipationFormatCard/ParticipationFormatCard";

import SpeakersGrid from "../../modules/SpeakersGrid/SpeakersGrid";

import QuestionAnswer from "../../modules/QuestionAnswer/QuestionAnswer";

import styles from "./Home.module.css";

function Home() {
  const navigate = useNavigate();
  const runningLineRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    const line = runningLineRef.current;

    // Дублируем контент
    line.innerHTML += line.innerHTML;
    line.innerHTML += line.innerHTML;

    const lineWidth = line.scrollWidth / 2;
    line.innerHTML += line.innerHTML;
    line.innerHTML += line.innerHTML;

    // Анимируем строку
    gsap.to(line, {
      x: `-${lineWidth}px`, // Сдвиг на ширину половины строки (поскольку контент дублируется)
      duration: 40,
      ease: "linear",
      repeat: -1,
    });
  }, []);

  return (
    <MainLayout>
      <div className={styles.home} id="home">
        <SocialButtons />
        {/*---main---*/}
        <div className={styles.main} id="main">
          <div className={styles.main_img}>
            <img src={illustration} />
          </div>
          <div className={styles.main_img_mobile}>
            <img src={illustrationMobile} />
          </div>
          <div className={styles.main_heading}>
            <div className={styles.name}>
              <span className={styles.heading}>Тюменский</span>
              <span className={styles.heading}>градостроительный</span>
              <div className={styles.name_line}>
                <span className={styles.heading}>форум</span>
                <span className={styles.heading}>2024</span>
              </div>
            </div>
            <span className={styles.heading_small}>7-8 ноября</span>
          </div>
        </div>

        {/*---line---*/}
        <div className={styles.running_line_wrapper}>
          <div className={styles.running_line} ref={runningLineRef}>
            <span>цифровая трансформация</span>
            <span>идентичность</span>
            <span>КРТ</span>
            <span>городские инициативы</span>
            <span>агломерации</span>
            <span>ИЖС</span>
            <span>концессии</span>
            <span>кампусы</span>
          </div>
        </div>

        {/*---mission---*/}
        <div className={styles.container} id="mission">
          <div className={styles.mission}>
            <div className={styles.text_block}>
              <span className={styles.heading_light}>миссия</span>
              <div className={styles.name}>
                <span className={styles.heading}>Тюменский</span>
                <span className={styles.heading}>градостроительный</span>
                <span className={styles.heading}>форум</span>
              </div>
              <div className={styles.text}>
                <p>
                  — это платформа для открытого диалога, созданная с целью
                  всестороннего обсуждения темы развития городов, а также
                  продвижения совместных инициатив. Форум объединяет
                  представителей органов государственной власти и бизнеса,
                  экспертов в области урбанистики и архитектуры, университеты и
                  институты развития, финансовые организации, IT компании и
                  многих других участников, способных оказать влияние на
                  развитие городов нашей страны.
                </p>
              </div>
            </div>
            <div className={styles.side_img_block}>
              <img src={missionSide} />
            </div>
          </div>

          <div className={styles.mission}>
            <div className={styles.row}>
              <span
                onClick={() => {
                  console.log("aa");
                  navigate("/opportunities");
                }}
                className={styles.btn}
              >
                <span className={styles.weight_600}>Купить билет </span>| стать
                участником форума
              </span>
              <span className={styles.btn_mobile}>
                <span className={styles.weight_600}>Купить билет</span>
              </span>
              <Link to="/program">Смотреть программу форума</Link>
            </div>
          </div>

          <div
            className={styles.dark_card}
            onClick={() => window.open("https://t.me/gradforum", "_blank")}
          >
            <CardLink />
          </div>
        </div>

        {/*---city-block---*/}
        <div className={styles.container_top_border}>
          <div className={styles.text_row}>
            <span className={styles.heading_light_big}>*</span>
            <div className={styles.name}>
              <span className={styles.heading}>Город,</span>
              <span className={styles.heading}>
                как платформа для инноваций:
              </span>
              <span className={styles.heading}>
                технологии развития городов
              </span>
              <div className={styles.margin_top_text}>
                <span className={styles.heading_light}>
                  Ключевая повестка 2024 года
                </span>
              </div>
            </div>
          </div>
          {/*---mobile---*/}
          <div className={styles.text_row_mobile}>
            <div className={styles.name}>
              <span className={styles.heading_city_block}>
                Город, как платформа
              </span>
              <span className={styles.heading_city_block}>
                для инноваций: технологии
              </span>
              <span className={styles.heading_city_block}>
                развития городов
              </span>
              <div className={styles.margin_top_text}>
                <span className={styles.heading_light_big_city_block}>*</span>
                <div className={styles.name}>
                  <span className={styles.heading_light_city_block}>
                    Ключевая повестка
                  </span>
                  <span className={styles.heading_light_city_block}>
                    2024 года
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*---office-img---*/}
        <div className={styles.img_cover}>
          <img src={office} />
        </div>

        {/*---platform---*/}
        <div className={styles.container_top_border} id="platform">
          <div className={styles.platform}>
            <div className={styles.headingWrapper}>
              <span className={styles.heading_light_big_city_block}>
                Площадка форума
              </span>
              <img
                onClick={() =>
                  window.open("https://tyumen.brusnika.ru/", "_blank").focus()
                }
                src={brusnikaLogo}
                alt="Логотип"
                className={styles.logo}
              />
            </div>
            <div className={styles.headingWrapper}>
              <span className={styles.heading}>1А Первомайская</span>
            </div>
            <span></span>
            <span className={styles.platform_text}>
              В этом году Тюменский градостроительный форум пройдет в
              современном офисном центре мирового уровня с панорамным видом на
              исторический центр города, созданном компанией «Брусника».
            </span>
            <div className={styles.platform_address_wrapper}>
              <span
                className={styles.platform_address}
                onClick={() =>
                  window.open("https://go.2gis.com/litop", "_blank").focus()
                }
              >
                г.Тюмень, ул.Первомайская 1А.
              </span>
            </div>
          </div>
        </div>

        {/*---construction-img---*/}
        <div className={styles.img_cover}>
          <img src={construction} />
        </div>

        {/*---map---*/}
        <div className={styles.container_top_border} id="exhibition-area">
          <div className={styles.map}>
            <div className={styles.map_header_wrapper}>
              <span className={styles.heading}>Экспозиционная зона</span>
            </div>
            <div className={styles.map_desktop}>
              <ExhibitionMap />
            </div>
            <div className={styles.map_mobile}>
              <ExhibitionMapMobile />
            </div>
          </div>
        </div>

        {/*---participation-format---*/}
        <div className={styles.container_top_border}>
          <div
            className={styles.participation_format}
            id="#participation-format"
          >
            <div className={styles.name}>
              <span className={styles.heading}>выбери подходящий</span>
              <span className={styles.heading}>формат участия</span>
            </div>
            <dic className={styles.formats}>
              <div className={styles.flex}>
                <ParticipationFormatCard format={"vip"} />
              </div>
              <div className={styles.flex}>
                <ParticipationFormatCard format={"business"} />
              </div>
            </dic>
          </div>
        </div>

        {/*---speakers---*/}
        <div className={styles.container_top_border} id="speakers">
          <div>
            <div className={styles.map_header_wrapper}>
              <span className={styles.heading}>Спикеры</span>
            </div>
            <div className={styles.speakers}>
              <SpeakersGrid />
            </div>
          </div>
        </div>

        {/*---qna---*/}
        <div className={styles.container_top_border} id="qna">
          <div>
            <div className={styles.map_header_wrapper}>
              <span className={styles.heading}>вопрос-ответ</span>
            </div>
            <div className={styles.qna}>
              <QuestionAnswer />
            </div>
          </div>
        </div>

        {/*---partners---*/}
        <div className={styles.partners} id={"partners"}>
          <div className={styles.margin_left_8}>
            <span className={styles.heading}>Партнеры</span>
          </div>
          <div className={styles.partners_carousel}>
            <PartnersCarousel />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Home;
