import React, { useEffect, useState } from "react";
import styles from "./ProfileEvents.module.css";
import EventsCalendar from "../../components/EventsCalendar/EventsCalendar";
import { useGetQuery } from "../../store/slices/apiSlice";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { json, useNavigate } from "react-router-dom";

export default function ProfileEvents() {
  const profileEP = useSelector((state) => state.endpoints.profile);
  const { data: eventsData, isLoading, isError } = useGetQuery(`${profileEP}/events/favorites`, {
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [formatedDates, setFormatedDates] = useState([]);

  useEffect(() => {
    if (eventsData && eventsData.length > 0) {
      const formattedEvents = eventsData.map((event) => ({
        id: event.id,
        name: event.title,
        descr: event.content,
        date: new Date(event.dateTime).toLocaleDateString("ru-RU").slice(0, 5),
        time: new Date(event.dateTime).toLocaleTimeString("ru").slice(0, 5),
        finishTime: !event.finishTime ? "" : new Date(event.finishTime).toLocaleTimeString("ru").slice(0, 5),
        place: event.place,
        tag: event.tags,
        moderator: event.moderators?.map((mod) => ({
          name: mod,
        })),
        speakers: event.speakers?.map((speaker) => ({
          name: speaker,
        })),
        minorSpeakers: event.minorSpeakers,
        isFavorite: event.isFavorite,
      }));

      setEvents(formattedEvents);

      const dates = formattedEvents.map((event) => event.date);
      const uniqueDates = [...new Set(dates)].sort(
        (a, b) => new Date(a) - new Date(b)
      );
      setFormatedDates(uniqueDates);
    } else {
      setEvents([]);
      setFormatedDates([]);
    }
  }, [eventsData]);

  const groupByDate = events.reduce((acc, event) => {
    // Если в аккумуляторе уже есть запись для этой даты, добавляем к ней
    if (!acc[event.date]) {
      acc[event.date] = [];
    }
    acc[event.date].push(event);
    return acc;
  }, {});

  if (isLoading) return <p>Загрузка...</p>;
  if (isError) return <p>Ошибка при загрузке данных</p>;

  console.log(formatedDates);
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <h2>Мои события</h2>
        <Button
          label="Все события"
          icon="pi pi-list"
          className={styles.order_btn}
          onClick={() => navigate("/program")}
        />
      </div>
      {formatedDates == null || formatedDates?.length === 0 ? (
        <>
          <p style={ { maxWidth: "700px", lineHeight: "1.5em" } }>
            В этом разделе будут отображаться, выбранные вами, события форума.<br/>
            Чтобы добавить желаемое мероприятие, перейдите на страницу с <a href="/program" style={ { color: "var(--highlight-mid)" } } >программой форума </a>
            и нажмите на флажок рядом с интересующим вас событием.
          </p>
        </>
      ) : (
        <EventsCalendar
        user={true}
        dates={formatedDates}
        groupByDate={groupByDate}
      />
      )}
     
    </div>
  );
}
