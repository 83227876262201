import React from "react";
import styles from "./UrbanTourProgramCard.module.css";

export default function UrbanTourProgramCard({
  eventName = "",
  descr = "",
  img = "",
}) {
  console.log(img);
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <span>{eventName}</span>
        <div>
          <img
                src={img}
                alt={eventName}
                className={styles.logo}
          />
        </div>
      </div>
      <div className={styles.descr}>
        <ul className={styles.dashed}>
          {descr.map((item) => {
            return <li>{item}</li>;
          })}
        </ul>
      </div>
    </div>
  );
}
