import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import RegistrationForm from "../../components/RegistrationForm/RegistrationForm";
import styles from "./RegistrationPage.module.css";

function RegistrationPage() {
  const navigate = useNavigate();

  return (
    <div id="registrationPage" className={styles.auth_page}>
      <div className={styles.back_arrow}>
        <Button
          label="Назад"
          icon="pi pi-arrow-left"
          outlined
          rounded
          text
          style={{ color: "var(--highlight-dark)" }}
          onClick={() => navigate(-1)}
        />
      </div>
      <div className={styles.wrapper}>
        <RegistrationForm />
      </div>
    </div>
  );
}

export default RegistrationPage;
