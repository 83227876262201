import styles from './EventCard.module.css';

export default function EventTime({ event }) {
  return (
    <div className={styles.column}>
      <p className={styles.event_time}>
        { event?.time } { event?.finishTime ?? "" }
      </p>
      
     
    </div>
  );
}
