import React from 'react';
import styles from './UserOrder.module.css';
import { Link } from 'react-router-dom';
import getStatusLabel from '../../hooks/useCheckStatus';


export default function UserOrder({ orderNumber, orderDate, orderStatus }) {
    return (
        <Link to={`./create/${orderNumber}`}>
            <div className={styles.card}>
                <div className={styles.info}>
                    <h3>Заказ от {orderDate}</h3>
                    <p>№{orderNumber}</p>
                </div>
                {getStatusLabel(orderStatus)}
            </div>
        </Link>
    )
}
