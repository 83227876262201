import React, { useState } from "react";
import { Carousel } from "primereact/carousel";
import { Galleria } from "primereact/galleria";
import styles from "./HotelsCarousel.module.css";

export default function HotelsCarousel({ hotelName = "" }) {
  const hilton = [
    {
      id: 1,
      src: "hilton_1.JPG",
    },
    {
      id: 2,
      src: "hilton_2.JPG",
    },
    {
      id: 3,
      src: "hilton_3.JPG",
    },
    {
      id: 4,
      src: "hilton_4.JPG",
    },
    {
      id: 5,
      src: "hilton_5.JPG",
    },
    {
      id: 6,
      src: "hilton_6.JPG",
    },
  ];

  const evrazia = [
    {
      id: 1,
      src: "evrazia_1.JPG",
    },
    {
      id: 2,
      src: "evrazia_2.JPG",
    },
    {
      id: 3,
      src: "evrazia_3.JPG",
    },
    {
      id: 4,
      src: "evrazia_4.JPG",
    },
    {
      id: 5,
      src: "evrazia_5.JPG",
    },
    {
      id: 6,
      src: "evrazia_6.JPG",
    },
    {
      id: 7,
      src: "evrazia_7.JPG",
    },
  ];

  const opera = [
    {
      id: 1,
      src: "opera_1.jpg",
    },
    {
      id: 2,
      src: "opera_2.jpg",
    },
    {
      id: 3,
      src: "opera_3.jpg",
    },
    {
      id: 4,
      src: "opera_4.jpg",
    },
    {
      id: 5,
      src: "opera_5.jpg",
    },
    {
      id: 6,
      src: "opera_6.jpg",
    },
    {
      id: 7,
      src: "opera_7.jpg",
    },
  ];

  const spasskaya = [
    {
      id: 1,
      src: "spasskaya_1.JPG",
    },
    {
      id: 2,
      src: "spasskaya_2.JPG",
    },
    {
      id: 3,
      src: "spasskaya_3.JPG",
    },
    {
      id: 4,
      src: "spasskaya_4.JPG",
    },
    {
      id: 5,
      src: "spasskaya_5.JPG",
    },
  ];

  const getHotel = () => {
    switch (hotelName) {
      case "hilton":
        return hilton;
      case "evrazia":
        return evrazia;
      case "opera":
        return opera;
      case "spasskaya":
        return spasskaya;
      default:
        return [];
    }
  };

  const [hotelImages, setHotelImages] = useState(getHotel());

  const hotelTemplate = (hotel) => {
    return (
      <div className={styles.list_item}>
        <img
          className={styles.hotel_img}
          src={require(`./images/${hotelName}/${hotel.src}`)}
        />
      </div>
    );
  };

  const itemTemplate = (hotel) => {
    return (
      <div className={styles.list_item}>
        <img
          className={styles.hotel_img}
          src={require(`./images/${hotelName}/${hotel.src}`)}
        />
      </div>
    );
  };

  const thumbnailTemplate = (hotel) => {
    return (
      <img
        src={require(`./images/${hotelName}/${hotel.src}`)}
        style={{ display: "block" }}
      />
    );
  };

  return (
    <>
      <Carousel
        value={hotelImages}
        numScroll={1}
        numVisible={1}
        itemTemplate={hotelTemplate}
        className={styles.desktop}
      />
      <Galleria
        value={hotelImages}
        numVisible={1}
        circular
        className={styles.mobile}
        showThumbnails={false}
        showItemNavigators
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />
    </>
  );
}
