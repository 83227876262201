import React from "react";
import { Button } from "primereact/button";
import EventCardCreateForm from "../../components/EventCardCreateForm/EventCardCreateForm";
import { useNavigate } from "react-router";
import { useAddEventMutation } from "../../store/slices/apiSlice";
import styles from "./EventForm.module.css";

export default function CreateEventForm() {
  const navigate = useNavigate();
  const [addEvent, { isLoading }] = useAddEventMutation();

  const handleSave = async (event) => {
    try {
      await addEvent({ body: event }).unwrap();
      navigate(-1);
    } catch (error) {
      console.error("Failed to create event:", error);
    }
  };

  return (
    <div className={styles.card}>
      <div>
        <Button
          label="Назад"
          icon="pi pi-angle-left"
          className={styles.go_back_btn}
          onClick={() => navigate(-1)}
        />
      </div>
      <h2>Создание события</h2>
      <EventCardCreateForm onSave={handleSave} isLoading={isLoading} />
    </div>
  );
}
