import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useGetProfileStatusQuery} from "../store/slices/apiSlice"

const useAdminService = (redirect = false) => {
  const navigate = useNavigate();
  const { data: profileStatus, isLoading } = useGetProfileStatusQuery();

  const isAdmin = () => {
    return profileStatus?.isAdmin || false;
  };

  useEffect(() => {
    if (redirect && !isLoading && !isAdmin()) {
      navigate('/profile');  // Перенаправляем неадминистратора на страницу профиля
    }
  }, [redirect, isLoading, navigate]);

  return {
    isAdmin: isAdmin(),
    isLoading,
  };
};

export default useAdminService;
