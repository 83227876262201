import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import LoginForm from "../../components/LoginForm/LoginForm";
import styles from "./LoginPage.module.css";

function LoginPage() {
  const navigate = useNavigate();

  return (
    <div id="loginPage" className={styles.auth_page}>
      <div className={styles.back_arrow}>
        <Button
          label="Назад"
          icon="pi pi-arrow-left"
          outlined
          rounded
          text
          style={{ color: "var(--highlight-dark)" }}
          onClick={() => navigate("/")}
        />
      </div>
      <div className={styles.wrapper}>
        <LoginForm />
      </div>
    </div>
  );
}

export default LoginPage;
