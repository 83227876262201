export default function YaMap({ src, width = 600, height = 400 }) {
  return (
    <div style={{ position: "relative", overflow: "hidden", width: "100%" }}>
      <iframe
        title="map"
        src={src}
        width={width}
        height={height}
        frameborder="0"
        allowfullscreen="true"
        style={{ position: "relative" }}
      ></iframe>
    </div>
  );
}
