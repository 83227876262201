import React from "react";
import exhibitionArea from "./images/exhibition-area-mobile.svg";
import areaL from "./images/area-l.svg";
import areaM from "./images/area-m.svg";
import areaS from "./images/area-s.svg";
import entrance from "./images/entrance-mobile.svg";
import elevator from "./images/elevator-mobile.svg";
import stairs from "./images/stairs-mobile.svg";
import service from "./images/service-mobile.svg";
import columns from "./images/columns-mobile.svg";
import meetingRooms from "./images/meeting-rooms-mobile.svg";
import styles from "./ExhibitionMap.module.css";
import { Link } from "react-router-dom";

export default function ExhibitionMapMobile() {
  return (
    <div className={styles.container_mobile}>
      <div className={styles.desrc_mobile}>
        <span className={styles.highlight_text_mobile}>
          Возможности для партнеров форума
        </span>
        <span className={styles.text_mobile}>
          Выберите подходящий для вас остров и оставьте заявку, наши менеджеры
          свяжутся с вами для получения возможности забронировать за собой место
          экспозиционной зоны
        </span>
      </div>

      <div className={styles.map_mobile}>
        <img src={exhibitionArea} />
      </div>

      <div className={styles.areas_mobile}>
        <img src={areaL} />
        <img src={areaM} />
        <img src={areaS} />
        <Link
          to="https://forms.yandex.ru/u/66beff43068ff05611502494/"
          
          className={ styles.btn } style={ { marginTop: "1rem" } }>
          Забронировать остров
        </Link>
      </div>
    </div>
  );
}
