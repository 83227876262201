import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist";
import { Button } from "primereact/button";
import NavbarDropdown from "../NavbarDropdown/NavbarDropdown";
import MobileMenu from "../MobileMenu/MobileMenu";
import logo from "../../images/logo.svg";
import mobileLogo from "../../images/mobile-logo.svg";
import telegram from "../../images/telegram.svg";
import user from "../../images/user.svg";
import arrowDown from "../../images/arrowDown.svg";
import bars from "../../images/bars.svg";
import styles from "./Navbar.module.css";

function Navbar() {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);

  const handleLogoClick = () => {
    navigate("/#home");
    setDropdown(false);
    setMobileActive(false);
  }

  const menuStart = (
    <>
      <div className={styles.desktop_logo}>
        <img
          alt="logo"
          src={ logo }
          height="62"
          className={ styles.logo }
          onClick={ handleLogoClick}
        />
      </div>
      <div className={styles.mobile_logo}>
        <img
          alt="logo"
          src={mobileLogo}
          width={120}
          className={ styles.logo }
          onClick={ handleLogoClick}
        />
      </div>
    </>
  );

  const menuEnd = (
    <div className={styles.container}>
      <div className={styles.btn_wrapper}>
        <Button
          label="Регистрация"
          className={styles.custom_btn}
          rounded
          onClick={() => navigate("/registration")}
        />
      </div>
      <div className={styles.nav_icons}>
        <div className={styles.icon}>
          <img
            src={telegram}
            onClick={() => window.open("https://t.me/gradforum", "_blank")}
          />
        </div>
        <div className={styles.icon}>
          <img src={user} onClick={() => navigate("/profile")} />
        </div>
      </div>
      <div className={styles.nav_icons_mobile}>
        <img src={bars} onClick={() => setMobileActive(!mobileActive)} />
        { mobileActive && <MobileMenu setActive={ setMobileActive} />}
      </div>
    </div>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.start}>{menuStart}</div>

      <div className={styles.links}>
        <ul>
          <li onClick={() => setDropdown(!dropdown)}>
            <div className={styles.row}>
              <a style={{cursor: 'pointer', userSelect: 'none', WebkitUserSelect: 'none'}}>О форуме</a>
              <img
                src={arrowDown}
                className={dropdown ? styles.arrow_clicked : styles.arrow}
              />
              {dropdown && <NavbarDropdown />}
            </div>
          </li>

          <li>
            <Link to="/program">Программа</Link>
          </li>
        </ul>
      </div>

      <div className={styles.end}>{menuEnd}</div>
    </div>
  );
}

export default Navbar;
