import React from "react";
import exhibitionArea from "./images/exhibition-area.svg";
import areaL from "./images/area-l.svg";
import areaM from "./images/area-m.svg";
import areaS from "./images/area-s.svg";
import entrance from "./images/entrance.svg";
import elevator from "./images/elevator.svg";
import stairs from "./images/stairs.svg";
import service from "./images/service.svg";
import columns from "./images/columns.svg";
import meetingRooms from "./images/meeting-rooms.svg";
import styles from "./ExhibitionMap.module.css";
import { Link } from "react-router-dom";


export default function ExhibitionMap() {
  return (
    <div className={styles.container}>
      <div className={styles.map_wrapper}>
        <div className={styles.map}>
          <img src={exhibitionArea} />
        </div>
      </div>
      <div className={styles.descr_block}>
        <div className={styles.desrc}>
          <span className={styles.highlight_text}>
            Возможности для партнеров форума
          </span>
          <span className={styles.text}>
            Выберите подходящий для вас остров и оставьте заявку, наши менеджеры
            свяжутся с вами для получения возможности забронировать за собой
            место экспозиционной зоны
          </span>
          <div className={styles.area_wrapper}>
            <img src={areaL} />
          </div>
          <div className={styles.area_wrapper}>
            <img src={areaM} />
          </div>
          <div className={styles.area_wrapper}>
            <img src={areaS} />
          </div>
          <Link
            to="https://forms.yandex.ru/u/66beff43068ff05611502494/"
            className={ styles.btn }>Забронировать остров</Link>
        </div>
      </div>
    </div>
  );
}
