import React, { useState, useEffect } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import SocialButtons from "../../components/SocialButtons/SocialButtons";
import CardLink from "../../components/CardLink/CardLink";
import YaMap from "../../components/YaMap/YaMap";
import HotelsCarousel from "../../components/HotelsCarousel/HotelsCarousel";
import { Dialog } from "primereact/dialog";
import background from "./images/background-city.svg";
import backgroundMobile from "./images/backgroud-mobile.svg";
import sideImg from "./images/woman.svg";
import styles from "./CityGuestsInfo.module.css";

export default function CityGuestsInfo() {

  const hilton =
    "https://yandex.ru/map-widget/v1/?ll=65.562724%2C57.155847&mode=search&oid=1024636866&ol=biz&sctx=ZAAAAAgBEAAaKAoSCcfWM4RjYVBAEQys4%2FihlExAEhIJRfKVQErsij8Rml5iLNMvcT8iBgABAgMEBSgKOABAsJ8NSAFqAnJ1nQHNzEw9oAEAqAEAvQGhwJi9wgEFwu%2FK6AOCAhtEb3VibGV0cmVlIGJ5IEhpbHRvbiBUeXVtZW6KAgCSAgI1NZoCDGRlc2t0b3AtbWFwcw%3D%3D&sll=65.562724%2C57.155847&sspn=0.052584%2C0.016787&text=Doubletree%20by%20Hilton%20Tyumen&z=14.78";
  const evrazia =
    "https://yandex.ru/map-widget/v1/?ll=65.538238%2C57.158799&mode=search&oid=1063370279&ol=biz&sctx=ZAAAAAgBEAAaKAoSCQCRfvs6YlBAEawb746Mk0xAEhIJaXBbW3heuj8RRBX%2BDG%2FWoD8iBgABAgMEBSgKOABAsJ8NSAFqAnJ1nQHNzEw9oAEAqAEAvQHxDNs0wgEFp%2FyG%2BwOCAibQkdC40LfQvdC10YEt0L7RgtC10LvRjCDQldCy0YDQsNC30LjRj4oCAJICAJoCDGRlc2t0b3AtbWFwcw%3D%3D&sll=65.538238%2C57.158799&sspn=0.103004%2C0.032880&text=%D0%91%D0%B8%D0%B7%D0%BD%D0%B5%D1%81-%D0%BE%D1%82%D0%B5%D0%BB%D1%8C%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F&z=13.81";
  const spasskaya =
    "https://yandex.ru/map-widget/v1/?ll=65.523783%2C57.162339&mode=search&oid=1075324632&ol=biz&sctx=ZAAAAAgBEAAaKAoSCU6Zm29EYlBAEdDSFWwjikxAEhIJjSYXY2Adlz8RWrqCbcSTfT8iBgABAgMEBSgKOABAsJ8NSAFqAnJ1nQHNzEw9oAEAqAEAvQE98SVowgEF2M3ggASCAhZXZXN0ZXJuIFBsdXMgU3Bhc3NrYXlhigIAkgICNTWaAgxkZXNrdG9wLW1hcHM%3D&sll=65.523783%2C57.162339&sspn=0.029786%2C0.009507&text=Western%20Plus%20Spasskaya&z=15.6";
  const opera =
    "https://yandex.ru/map-widget/v1/?ll=65.539547%2C57.079206&mode=search&oid=102711880493&ol=biz&sctx=ZAAAAAgBEAAaKAoSCcfWM4RjYVBAEQys4%2FihlExAEhIJRfKVQErsij8Rml5iLNMvcT8iBgABAgMEBSgKOABAsJ8NSAFqAnJ1nQHNzEw9oAEAqAEAvQGhwJi9wgEFwu%2FK6AOCAhtEb3VibGV0cmVlIGJ5IEhpbHRvbiBUeXVtZW6KAgCSAgI1NZoCDGRlc2t0b3AtbWFwcw%3D%3D&sll=65.539547%2C57.079206&sspn=0.014586%2C0.004666&text=Doubletree%20by%20Hilton%20Tyumen&z=16.63";

  const [selectedHotel, setSelectedHotel] = useState(hilton);
  const [learnMore, setLearnMore] = useState("");

  const [visible, setVisible] = useState(false);

  const getHotel = () => {
    switch (learnMore) {
      case "hilton":
        return {
          name: "DoubleTree by Hilton Tyumen",
          link: "https://www.hilton.ru/hotels/doubletree-by-hilton-hotel-tyumen/",
        };
      case "evrazia":
        return {
          name: "Бизнес-отель Евразия",
          link: "https://www.eurasiahotel.ru/",
        };
      case "spasskaya":
        return {
          name: "Best Western Plus Spasskaya",
          link: "https://hotel-spasskaya.ru/",
        };
      case "opera":
        return {
          name: "Opera Wellness Hotel",
          link: "https://hotel.operatmn.ru/",
        };
      default:
        return {};
    }
  };

  const headerElement = (
    <div className={styles.dialog_header}>
      <span className={styles.dialog_name}>{getHotel()?.name}</span>
      <span
        className={styles.btn}
        onClick={() => window.open(getHotel()?.link, "_blank")}
      >
        Забронировать номер в этом отеле
      </span>
    </div>
  );

  const footerContent = (
    <div className={styles.dialog_footer}>
      <span
        className={styles.btn_mobile}
        onClick={() => window.open(getHotel()?.link, "_blank")}
      >
        Забронировать номер в этом отеле
      </span>
    </div>
  );

  return (
    <MainLayout>
      <div className={styles.container} id="city-guests-info">
        <SocialButtons />

        <div className={styles.block}>
          <div className={styles.column}>
            <div className={styles.row}>
              <div className={styles.heading}>
                <span>Информация для гостей</span>
                <span>города</span>
              </div>
              <div className={styles.heading_mobile}>
                <span>Информация</span>
                <span>для гостей</span>
                <span>города</span>
              </div>
              <div className={styles.side_img}>
                <img src={sideImg} />
              </div>
            </div>
            <div className={styles.text}>
              <p>
                <span className={styles.font_weight_600_uppercase}>
                  Добро пожаловать в Тюмень!
                </span>
              </p>
              <p>
                Наш город - это место, где история и современность гармонично
                сочетаются друг с другом. Здесь вы найдете уникальную атмосферу,
                богатую культурную жизнь и множество интересных
                достопримечательностей.
              </p>
              <p>
                Сегодня Тюмень является одним из ведущих экономических,
                культурных и технологических центров России. Гости города могут
                насладиться уникальной природой, богатым историческим наследием
                и современной инфраструктурой города.
              </p>
              <p>Надеемся, что ваше пребывание станет незабываемым!</p>
              <div className={styles.link_text}>
                <p>
                  <span className={styles.font_weight_600}>
                    Вы всегда можете обратиться{" "}
                    <span
                      className={styles.underlined}
                      onClick={() =>
                        window.open("https://t.me/gradforum_bot", "_blank")
                      }
                    >
                      к нам за дополнительной информацией
                    </span>{" "}
                    в организации вашего отдыха.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className={styles.background_img}>
            <img src={background} />
          </div>
          <div className={styles.background_img_mobile}>
            <img src={backgroundMobile} />
          </div>
        </div>
        <div className={styles.container_top_border} id="hotels">
          <div className={styles.heading_small} >
            <span>подобрали отели для вашего удобства:</span>
          </div>
          <div className={styles.hotels}>
            <div className={styles.hotels_names}>
              <div className={styles.hotel_name_wrapper}>
                <span
                  className={styles.hotel_name}
                  onClick={() => setSelectedHotel(hilton)}
                >
                  DoubleTree by Hilton Tyumen
                </span>
                <span
                  className={styles.learn_more_link}
                  onClick={() => {
                    setLearnMore("hilton");
                    setVisible(true);
                  }}
                >
                  Подробнее
                </span>
              </div>
              <div className={styles.hotel_name_wrapper}>
                <span
                  className={styles.hotel_name}
                  onClick={() => setSelectedHotel(evrazia)}
                >
                  Бизнес-отель Евразия
                </span>
                <span
                  className={styles.learn_more_link}
                  onClick={() => {
                    setLearnMore("evrazia");
                    setVisible(true);
                  }}
                >
                  Подробнее
                </span>
              </div>
              <div className={styles.hotel_name_wrapper}>
                <span
                  className={styles.hotel_name}
                  onClick={() => setSelectedHotel(spasskaya)}
                >
                  Best Western Plus Spasskaya
                </span>
                <span
                  className={styles.learn_more_link}
                  onClick={() => {
                    setLearnMore("spasskaya");
                    setVisible(true);
                  }}
                >
                  Подробнее
                </span>
              </div>
              <div className={styles.hotel_name_wrapper}>
                <span
                  className={styles.hotel_name}
                  onClick={() => setSelectedHotel(opera)}
                >
                  Opera Wellness Hotel
                </span>
                <span
                  className={styles.learn_more_link}
                  onClick={() => {
                    setLearnMore("opera");
                    setVisible(true);
                  }}
                >
                  Подробнее
                </span>
              </div>
            </div>
            <div className={styles.map}>
              <YaMap src={selectedHotel} width={"100%"} height={"100%"} />
            </div>
            <Dialog
              header={headerElement}
              footer={footerContent}
              visible={visible}
              className={styles.dialog}
              onHide={() => {
                if (!visible) return;
                setVisible(false);
              }}
            >
              <HotelsCarousel hotelName={learnMore} />
            </Dialog>
          </div>
        </div>

        <div className={styles.map_mobile}>
          <YaMap src={selectedHotel} width={"100%"} height={"100%"} />
        </div>

        <div className={styles.card_container}>
          <div
            className={styles.card}
            onClick={() => window.open("https://t.me/gradforum", "_blank")}
          >
            <CardLink />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
