import React, { useState, useEffect, useRef } from "react";
import styles from "./ContactsInfoCard.module.css";
import ContactInfo from "../../components/ContactInfo/ContactInfo";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast"; // Импортируем Toast
import { useGetContactDataQuery, useSaveContactDataMutation } from "../../store/slices/apiSlice";

export default function ContactInfoCard() {
  const toast = useRef(null); // Создаем реф для Toast

  // Выполняем GET запрос для получения контактных данных
  const { data: initialData, isLoading: isLoadingData } = useGetContactDataQuery();

  // Используем useState для хранения контактных данных
  const [contactData, setContactData] = useState({
    phoneNumber: "",
    email: "",
    messengers: [],
  });

  const [saveContactData, { isLoading: isSaving }] = useSaveContactDataMutation();

  // Обновляем состояние с данными, полученными из запроса
  useEffect(() => {
    if (initialData) {
      setContactData({
        phoneNumber: initialData.phoneNumber || "",
        email: initialData.email || "",
        messengers: initialData.messengers || [],
      });
    }
  }, [initialData]);

  const handleSave = async () => {
    try {
      await saveContactData(contactData).unwrap();
      toast.current.show({ severity: 'success', summary: 'Успех', detail: 'Контактная информация успешно сохранена', life: 3000 });
    } catch (error) {
      console.error("Ошибка при сохранении данных:", error);
      toast.current.show({ severity: 'error', summary: 'Ошибка', detail: 'Произошла ошибка при сохранении данных. Проверьте заполнены ли все данные', life: 3000 });
    }
  };

  if (isLoadingData) {
    return <div>Загрузка...</div>; // Отображаем загрузку, пока данные не получены
  }

  return (
    <div className={styles.card}>
      <h2>Контактная информация</h2>
      <ContactInfo contactData={contactData} setContactData={setContactData} />
      <Button
        label="Сохранить"
        className={styles.save_btn}
        onClick={handleSave}
        disabled={isSaving}
      />
      <Toast ref={toast} /> {/* Добавляем Toast */}
    </div>
  );
}
